"use client";
import React, { useState } from 'react';
import Facebook from '../icons/Facebook';
import LinkedIn from '../icons/LinkedIn';
import Instagram from '../icons/Instagram';
import Dribble from '../icons/Dribble';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { Zoom } from 'react-awesome-reveal';

import Close from '../icons/Close';

export default function HeroSection() {
  const [popupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className='mx-auto relative'>
      <div className="banner">
        {/* Video Background */}
        <video autoPlay muted loop className="video-bg">
          <source src="./videobanner.mp4" type="video/mp4" />
        </video>
        
        {/* Background Image */}
        <div className="background-image"></div>

        {/* Content */}
        <div className='lg:max-w-[1440px] max-w-[90%] lg:pb-[60px] pb-7 mx-auto lg:pt-[220px] pt-[120px] relative z-10'>
          <Zoom duration={500}>
            <h1 className='lg:text-[16px] text-center text-[12px] secondary-color font-semibold lg:font-bold tracking-[0.32px]'>TRANSFORMING IDEA INTO DIGITAL REALITY</h1>
            <h1 className='lg:text-[55px] text-center mt-3 w-[318px] lg:w-[600px] text-[32px] font-bold mx-auto lg:leading-[65px] leading-[40px]'>Where Imagination Meets <span className='secondary-color'>Functionality</span>.</h1>
            <div className='flex justify-center items-center  gap-5'>
            <Link href={'https://wa.me/+447471902132'} className=''>
  <button className="lg:mt-[32px] text-center mt-6 lg:w-[160px] w-[153px] lg:h-[49px] h-[45px] rounded-full text-white bg-[#540FE0] hover:shadow-md hover:shadow-purple-700 text-[12px] lg:text-[16px] font-medium tracking-[0.32px]">Call us anytime</button>
</Link>
              <button onClick={openPopup} className="lg:mt-[35px] text-center mt-6 lg:w-[100px] hover:shadow-md hover:shadow-purple-700 w-[153px] lg:h-[48px] h-[45px] rounded-full text-[#540FE0] border-[#540FE0] border-2 text-[12px] lg:text-[16px] font-medium tracking-[0.32px]">Hire us</button>
            </div>
            <div className='flex lg:w-[207px] w-[136px] justify-between items-center mt-[35px] lg:mt-[140px] mx-auto '>
  <Link href="https://www.facebook.com/Hastyheadltd" target="_blank" className='facebook-icon hover:border-b pb-1 hover:border-purple-700' aria-label="Facebook">
    <Facebook />
  </Link>
  <Link href="https://linkedin.com/company/hasty-head" target="_blank" className='linkedin-icon hover:border-b pb-1 hover:border-purple-700' aria-label="LinkedIn">
    <LinkedIn />
  </Link>
  <Link href="https://www.instagram.com/hastyheadltd" target="_blank" className='instagram-icon hover:border-b pb-1 hover:border-purple-700' aria-label="Instagram">
    <Instagram />
  </Link>
  <Link href="https://dribbble.com/Hastyheadltd" target="_blank" className='dribble-icon hover:border-b pb-1 hover:border-purple-700' aria-label="Dribbble">
    <Dribble />
  </Link>
</div>

          </Zoom>
        </div>
      </div>
      {/* Popup */}
      {popupOpen && (
          <div className="popup fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="popup-content bg-white p-8 lg:w-[700px] w-[330px] lg:h-[800px] h-[500px] rounded-lg">
            <button   onClick={closePopup}><Close/></button>
            <iframe src={process.env.calendly} className="calendly-frame  w-full h-full" />
          </div>
        </div>
      )}
    </div>
  )
}
