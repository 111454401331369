"use client";
import React from 'react'
import SectionTitle from '../ClassName/SectionTitle'
import SectionText from '../ClassName/SectionText'
import Web from '../icons/Web'
import Mobile from '../icons/Mobile'
import GetNotice from '../icons/GetNotice'
import StartUp from '../icons/StartUp'
import { Fade, Zoom } from 'react-awesome-reveal';


export default function Services() {
  return (
   
    <div className='lg:max-w-[1170px] max-w-[90%] mx-auto lg:pt-[70px] lg:pb-[70px] pb-8 pt-[50px]'>
      <div className='flex flex-col lg:flex-row lg:justify-between mx-auto justify-center lg:items-start items-center'>
      <Fade direction="left" duration={500}>
        <div className='text-center lg:text-left'>
          <SectionTitle text='Services We Provide' />
          <div className='lg:w-[360px] w-[90%] lg:mt-4 mb-6 mt-3 mx-auto lg:mx-0'>
            <SectionText text="At Hasty Head Ltd, We specialize in delivering top-notch digital solutions tailored to meet your business needs. From captivating web development to innovative software solutions, compelling graphic design to engaging content creation, and strategic digital marketing to immersive video production, We are your one-stop destination for all things digital." />
          </div>
        </div>
        </Fade>
        <Fade direction="right" duration={500}>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-[46px] justify-center lg:justify-start'>
          <div className='lg:w-[252px] w-[250px] text-center lg:text-left'>
            <Web />
            <h1 className='lg:mt-3 lg:text-[24px] text-[18px] font-bold leading-[34px] text-[#1B1724]'>Web Creation</h1>
            <p className='lg:mt-3 lg:text-[18px] text-[#1B1724] text-[14px] w-full opacity-80 font-medium lg:leading-[30px] leading-[24px]'>Professional, user-friendly websites designed to attract and engage.</p>
          </div>
          <div className='lg:w-[259px] w-[250px] text-center lg:text-left'>
            <Mobile />
            <h1 className='lg:mt-3 mt-1 lg:text-[24px] text-[18px] font-bold leading-[34px] text-[#1B1724]'>Mobile Apps</h1>
            <p className='lg:mt-3 lg:text-[18px] text-[#1B1724] text-[14px] opacity-80 w-full font-medium lg:leading-[30px] leading-[24px]'>Custom apps that deliver seamless experiences, wherever your customers are.</p>
          </div>
          <div className='lg:w-[240px] w-[250px] text-center lg:text-left'>
            <GetNotice />
            <h1 className='lg:mt-3 mt-1 lg:text-[24px] text-[18px] font-bold leading-[34px] text-[#1B1724]'>Get Noticed</h1>
            <p className='lg:mt-3 lg:text-[18px] text-[#1B1724] text-[14px] opacity-80 w-full font-medium lg:leading-[30px] leading-[24px]'>Content and SEO strategies that put you ahead of the competition.</p>
          </div>
          <div className='lg:w-[242px] w-[250px] text-center lg:text-left'>
            <StartUp />
            <h1 className='lg:mt-3 mt-1 lg:text-[24px] text-[18px] font-bold leading-[34px] text-[#1B1724]'>Startup Launchpad</h1>
            <p className='lg:mt-3 lg:text-[18px] text-[#1B1724] text-[14px] opacity-80 w-full font-medium lg:leading-[30px] leading-[24px]'>We build your MVP and map your journey to market and funding success.</p>
          </div>
        </div>
        </Fade>
      </div>
    </div>

  
  )
}
