"use client";
import React, { useState } from 'react';
import type { ReviewData } from '../Types/types';
import SectionTitle from '../ClassName/SectionTitle';
import Slider from 'react-slick';
import { Zoom } from 'react-awesome-reveal';
import Image from 'next/image';

interface Props {
  reviewsData: any;
}

export default function Review({ reviewsData }: Props) {
  const [sliderIndex, setSliderIndex] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    afterChange: (index: number) => {
      setSliderIndex(index);
    },
  };

  return (
    <div className='review pt-5  lg:h-[700px] h-[500px] ' >
       
      <div className='lg:max-w-[1440px] max-w-[90%] mx-auto'>
      <Zoom duration={500}>
        <div className='mx-auto lg:pt-[70px] pt-8 pb-[30px] text-center'>
          <SectionTitle text="What Client Say About Us" />
        </div>
       
        <div className=' mx-auto  lg:w-[774px] w-[320px] ' >
          <Slider {...settings}>
            {reviewsData?.map((review: ReviewData) => (
              <div key={review.id} className=''>
                  <Image src={review?.acf?.customer_image? review?.acf?.customer_image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmHkj6-Tndku8K2387sMaBf2DaiwfBtHQw951-fc9zzA&s"} alt='customerimg' className='lg:h-[128px]  h-[70px] mx-auto mb-4 lg:w-[128px] w-[70px] review-border object-cover'  height={128} width={128} />

             
               <div className='bg-white lg:mt-[-80px] mt-[-60px] lg:pb-[30px] pb-1 lg:mb-5 mb-3'>
               <h1 className='lg:pt-[66px] pt-11 lg:text-[24px] text-[18px] text-black lg:leading-[34px] leading-[24px] text-center font-bold tracking-[0.48px]'>{review?.acf?.customer_name }</h1>
                <p className='lg:text-[16px] text-[14px]  text-center lg:leading-[26px] leading-[20px] tracking-[0.32px] font-medium'>{review?.acf?.designation} at {review?.acf?.company_name}</p>
                <div className='mt-4 mb-4 lg:text-[18px] px-2 lg:px-0 text-[12px] lg:w-[584px] w-[320px] lg:h-[170px] h-[140px] overflow-hidden pb-3 lg:leading-[28px] leading-[14px] font-medium text-center mx-auto' dangerouslySetInnerHTML={{ __html: review?.acf?.customer_feedback }}></div>
               </div>
                
              </div>
            ))}
          </Slider>
        </div>
        </Zoom>
      </div>
      
    </div>
  );
}
