"use client";
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendToSlack } from '@/app/actions/sendToSlack';

import Swal from'sweetalert2';

export default function Contact() {
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!recaptchaToken) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please complete the reCAPTCHA",
        showConfirmButton: false,
        timer: 1000
      });
    
      return;
    }

    const formData = new FormData(e.currentTarget);
    formData.append('recaptchaToken', recaptchaToken);

    try {
      await sendToSlack(formData);
      Swal.fire({
        title: "Thank you for your message!",
        html: `
          <p>We appreciate your interest and will get back to you as soon as possible.</p>
          
        `,
        width: 600,
        padding: "2em",
        color: "#333",
        backdrop: `
          rgba(0, 0, 0, 0.4)
          center top
          no-repeat
        `
      });
      
     
    } catch (error) {
      alert('Failed to send message. Please try again later.');
    }
  };

  return (
    <div className='mx-auto mt-[70px] bg-[#1B1724]'>
      <div className='lg:max-w-[1408px] max-w-[90%] mx-auto'>
        <div className='lg:w-[890px] w-[90%] text-white lg:pt-[100px] pt-[50px] mx-auto'>
          <h1 className='lg:text-[32px] text-[22px] font-bold  tracking-[0.64px]'>
            Want to estimate a project?
          </h1>
          <p className='lg:text-[18px] text-[15px] lg:leading-[30px] leading-[25px] font-medium opacity-80 lg:w-[420px] w-[90%] mt-3'>
            Just tell us about your Project, what are your goals and let&#39;s start.
          </p>

          <form className='mt-10 mb-10 text-[18px] leading-[28px] font-medium' onSubmit={handleSubmit}>
            <div className='flex lg:flex-row flex-col'>
              Hello! it&#39;s
              <input
                type='text'
                id="name"
                name='name'
                placeholder='Your Name'
                className='border-b-2 border-white bg-[#1B1724] lg:w-[220px] w-[327px] mb-8 mt-3 lg:mt-0 lg:mx-5 mx-0 focus:outline-none'
                required
              />
              I&#39;m
              <input
                type='text'
                id="position"
                name='position'
                placeholder='Your position'
                className='border-b-2 border-white bg-[#1B1724] lg:w-[220px] w-[327px] mb-8 mt-3 lg:mt-0 lg:mx-5 mx-0 focus:outline-none'
                required
              />
              at
              <input
                type='text'
                id='company'
                name='company'
                placeholder='Your company name'
                className='border-b-2 border-white bg-[#1B1724] lg:w-[220px] w-[327px] mb-8 mt-3 lg:mt-0 ms-0 lg:ms-5 focus:outline-none'
                required
              />
            </div>
            <div className='mt-5 lg:pb-[60px] pb-6'>
              <h1 className='lg:hidden block text-white'>Project Details</h1>
              <textarea
                id='projectDetails'
                name='projectDetails'
                placeholder='Project details'
                required
                className='pt-[4px] mt-3 p-1 border-white bg-[#1B1724] lg:w-[890px] w-full focus:outline-none border-b-2'
              />
            </div>
            <div className='flex lg:flex-row flex-col'>
              Within
              <input
                type='text'
                id='timeEstimate'
                name='timeEstimate'
                placeholder='Time estimate'
                required
                className='border-b-2 border-white bg-[#1B1724] lg:w-[125px] w-[327px] mb-8 mt-3 lg:mt-0 lg:mx-3 mx-0 focus:outline-none'
              />
              <div className='flex lg:flex-row flex-col'>
                <h1>
                  with the budget of
                  <span>
                    <input
                      type='text'
                      id='budget'
                      name='budget'
                      required
                      placeholder='Amount'
                      className='border-b-2 border-white bg-[#1B1724] lg:w-[80px] w-[200px] mb-8 mt-3 lg:mt-0 lg:mx-3 mx-0 focus:outline-none'
                    />
                  </span>
                  Pound.
                </h1>
              </div>
              <p className='lg:ms-2'>
                Here&#39;s my
                <input
                  type='email'
                  id='email'
                  name='email'
                  required
                  placeholder='Your email'
                  className='border-b-2 border-white bg-[#1B1724] lg:w-[222px] lg:ms-3 focus:outline-none w-[327px] mb-8 mt-3 lg:mt-0 ms-0'
                />
              </p>
            </div>
            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
              onChange={handleRecaptchaChange}
              className='mt-5'
            />
            <div className='flex lg:flex-row flex-col justify-between items-center mt-[30px]'>
              <p className='text-[18px] leading-[30px] font-medium'>Let&#39;s talk soon!</p>
              <button type='submit' className='contact-btn bg-[#5D17EB] text-white mt-11'>
                Send Message
              </button>
            </div>
          </form>
        </div>
        <div className='lg:pt-[70px] pt-4 lg:pb-[50px]'>
          <div className='mx-auto lg:w-[1169px] h-[1px] bg-white opacity-20'></div>
        </div>
      </div>
    </div>
  );
}
