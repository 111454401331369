import React from 'react';

interface SectionTitleProps {
  text: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ text }) => {
  return (
   
      <h1 className="lg:text-[32px] text-[24px] text-[#1B1724] font-bold lg:tracking-[-0.64px] lg:leading-[45px]">{text}</h1>
    
  );
};

export default SectionTitle;
