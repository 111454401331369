import React from 'react';

interface SectionTextProps {
  text: string;
}

const SectionText: React.FC<SectionTextProps> = ({ text }) => {
  return (
    
      <p className="lg:text-[18px]  text-[12px] leading-[20px] lg:leading-[28px] opacity-80  text-[#100F15]">{text}</p>
    
  );
};

export default SectionText;
