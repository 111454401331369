"use client";
import React from 'react'
import Marquee from 'react-fast-marquee'
import { Fade, Zoom } from 'react-awesome-reveal'
import Image from 'next/image';

export default function TrustedBy() {
  return (
    <Zoom duration={500}>
       <div className=' lg:mt-[90px] mb-10 mt-[70px]'>
         <div className='mx-auto lg:w-[1170px]  w-[90%]'>
         <div className='  mx-auto flex justify-start items-center lg:gap-7 gap-5'>
            <div className='lg:h-[66px] h-[66px] lg:w-[6px] w-[3px] bg-[#540FE0]'></div>
            <div className='lg:w-[394px] w-[324px]'>
            <h1 className='lg:text-[32px] text-[24px] text-[#1B1724] leading-[35px] lg:leading-[38px] lg:tracking-[-0.64px] font-bold '>Highlighting Partnerships and Collaborations</h1>
           
            </div>

         </div>
         </div>
           
            <Marquee>
       

<div className='flex gap-[50px] lg:mt-[40px] mt-[50px] justify-start items-center'>
  <Image src='/logo/sparkblog.png' height={48} width={110} alt="sparkblog img"/>

  <Image src='/logo/job.png' height={48} width={110} alt="job img"/>
  <Image src='/logo/shahagro.png' height={48} width={110} alt="shahagro img"/>
  <Image src='/logo/rcbs.png' height={48} width={110} alt="rcbs logo"/>
  <Image src='/logo/ilogo.webp' height={58} width={110} alt="ilogo img"/>
  <Image src='/logo/codeark.png' height={48} width={110} alt="codeark logo"/>
  <Image src='/logo/highborn.jpg' height={58} width={110} alt="highborn logo"/>
  <Image src='/logo/chrity.webp' height={48} width={110} alt="chrity logo"/>
  <Image src='/logo/dentist.svg' height={48} width={110} className="me-11" alt="dentist logo"/>
</div>

              
                </Marquee>

            
    </div>
    </Zoom>
  )
}
