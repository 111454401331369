import React from 'react'

export default function Mobile() {
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="56" className='mx-auto lg:mx-0'  viewBox="0 0 36 56" fill="none">
  <path d="M28.2315 55.0967H7.76802C4.4297 55.0967 1.7417 52.3035 1.7417 48.8351V7.16526C1.7417 3.69642 4.4297 0.903198 7.76802 0.903198H28.2315C31.5698 0.903198 34.2578 3.69642 34.2578 7.16481V48.8798C34.2145 52.3035 31.5265 55.0967 28.2315 55.0967Z" fill="white"/>
  <path opacity="0.5" d="M34.2578 7.16481C34.2578 3.69642 31.5698 0.903198 28.2315 0.903198H7.76802C4.4297 0.903198 1.7417 3.69642 1.7417 7.16481V10.4078H34.2578V7.16481ZM1.7417 45.5465V48.8351C1.7417 52.3035 4.4297 55.0967 7.76802 55.0967H28.2315C31.5265 55.0967 34.2145 52.3035 34.2578 48.8798V45.546L1.7417 45.5465Z" fill="#540FE0"/>
  <path d="M18.0001 52.2354C19.0058 52.2354 19.821 51.3987 19.821 50.3666C19.821 49.3345 19.0058 48.4978 18.0001 48.4978C16.9944 48.4978 16.1792 49.3345 16.1792 50.3666C16.1792 51.3987 16.9944 52.2354 18.0001 52.2354Z" fill="#540FE0"/>
  <path d="M22.2922 6.44634H13.7079C13.4684 6.44634 13.2386 6.35118 13.0692 6.1818C12.8998 6.01241 12.8047 5.78267 12.8047 5.54312C12.8047 5.30357 12.8998 5.07383 13.0692 4.90444C13.2386 4.73505 13.4684 4.63989 13.7079 4.63989H22.2922C22.5317 4.63989 22.7615 4.73505 22.9308 4.90444C23.1002 5.07383 23.1954 5.30357 23.1954 5.54312C23.1954 5.78267 23.1002 6.01241 22.9308 6.1818C22.7615 6.35118 22.5317 6.44634 22.2922 6.44634Z" fill="white"/>
  <path d="M8.78711 15.3549H27.2129V40.6452H8.78711V15.3549Z" fill="#540FE0"/>
  <path d="M27.2128 41.5484H8.78701C8.54746 41.5484 8.31773 41.4532 8.14834 41.2838C7.97895 41.1144 7.88379 40.8847 7.88379 40.6451V15.3548C7.88379 15.1153 7.97895 14.8855 8.14834 14.7161C8.31773 14.5468 8.54746 14.4516 8.78701 14.4516H27.2128C27.4524 14.4516 27.6821 14.5468 27.8515 14.7161C28.0209 14.8855 28.116 15.1153 28.116 15.3548V40.6451C28.116 40.8847 28.0209 41.1144 27.8515 41.2838C27.6821 41.4532 27.4524 41.5484 27.2128 41.5484ZM9.69024 39.7419H26.3096V16.2581H9.69024V39.7419Z" fill="#540FE0"/>
  <path d="M22.9836 28.9032H13.0165C12.777 28.9032 12.5472 28.8081 12.3778 28.6387C12.2084 28.4693 12.1133 28.2396 12.1133 28C12.1133 27.7605 12.2084 27.5307 12.3778 27.3613C12.5472 27.192 12.777 27.0968 13.0165 27.0968H22.9836C23.2232 27.0968 23.4529 27.192 23.6223 27.3613C23.7917 27.5307 23.8868 27.7605 23.8868 28C23.8868 28.2396 23.7917 28.4693 23.6223 28.6387C23.4529 28.8081 23.2232 28.9032 22.9836 28.9032ZM22.9836 34.7742H13.0165C12.777 34.7742 12.5472 34.6791 12.3778 34.5097C12.2084 34.3403 12.1133 34.1105 12.1133 33.871C12.1133 33.6314 12.2084 33.4017 12.3778 33.2323C12.5472 33.0629 12.777 32.9678 13.0165 32.9678H22.9836C23.2232 32.9678 23.4529 33.0629 23.6223 33.2323C23.7917 33.4017 23.8868 33.6314 23.8868 33.871C23.8868 34.1105 23.7917 34.3403 23.6223 34.5097C23.4529 34.6791 23.2232 34.7742 22.9836 34.7742ZM22.9836 23.0323H13.0165C12.777 23.0323 12.5472 22.9371 12.3778 22.7677C12.2084 22.5983 12.1133 22.3686 12.1133 22.1291C12.1133 21.8895 12.2084 21.6598 12.3778 21.4904C12.5472 21.321 12.777 21.2258 13.0165 21.2258H22.9836C23.2232 21.2258 23.4529 21.321 23.6223 21.4904C23.7917 21.6598 23.8868 21.8895 23.8868 22.1291C23.8868 22.3686 23.7917 22.5983 23.6223 22.7677C23.4529 22.9371 23.2232 23.0323 22.9836 23.0323Z" fill="white"/>
  <path d="M28.2319 0H7.76842C3.94732 0 0.838867 3.21413 0.838867 7.16484V48.8352C0.838867 52.7859 3.94732 56 7.76842 56H28.2324C32.0038 56 35.1122 52.8112 35.1619 48.8799V7.16484C35.1614 3.21413 32.053 0 28.2319 0ZM33.355 44.6433H2.64532V11.3115H33.355V44.6433ZM7.76842 1.80645H28.2324C31.0567 1.80645 33.355 4.21039 33.355 7.16484V9.5051H2.64532V7.16484C2.64532 4.21039 4.94358 1.80645 7.76842 1.80645ZM28.2319 54.1935H7.76842C4.94358 54.1935 2.64532 51.7896 2.64532 48.8352V46.4497H33.355V48.8686C33.318 51.8045 31.0197 54.1935 28.2319 54.1935Z" fill="#540FE0"/>
</svg>
    </div>
  )
}
