import React from 'react'

export default function StartUp() {
  return (
    <div><svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" className='mx-auto lg:mx-0'  viewBox="0 0 56 56" fill="none">
    <g clip-path="url(#clip0_183_315)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M52.8506 32.5436H26.8353C26.4745 32.5436 26.1792 32.2484 26.1792 31.8875V16.5343C26.1792 16.1734 26.4745 15.8781 26.8353 15.8781H52.8506C53.2114 15.8781 53.5067 16.1734 53.5067 16.5343V31.8875C53.5067 32.2484 53.2114 32.5436 52.8506 32.5436ZM44.8787 28.0492C44.5178 28.0492 44.2225 27.7539 44.2225 27.3931V21.0943C44.2225 20.7006 44.5178 20.4382 44.8787 20.4382H47.9296C49.4059 20.4382 50.6197 21.6192 50.6197 23.0955C50.6197 24.5718 49.4059 25.7856 47.9296 25.7856H45.5348C45.5348 26.8354 45.7644 28.0492 44.8787 28.0492ZM45.5348 24.4405H47.9296C49.734 24.4405 49.734 21.7176 47.9296 21.7176H45.5348V24.4405ZM39.8265 28.0492C39.5641 28.0492 39.3344 27.8852 39.236 27.6555L36.5131 21.324C36.1523 20.5366 37.3661 20.0117 37.6941 20.7991L39.8265 25.7528L41.9589 20.7991C42.3198 20.0117 43.5008 20.5366 43.1728 21.324L40.4498 27.6555C40.3514 27.8852 40.089 28.0492 39.8265 28.0492ZM34.7744 28.0492C34.4135 28.0492 34.1183 27.7539 34.1183 27.3931V24.2109L32.8716 27.1306C32.642 27.6555 31.8875 27.6555 31.6578 27.1306L30.4112 24.2109V27.3931C30.4112 28.246 29.0989 28.246 29.0989 27.3931V21.0615C29.0989 20.3398 30.0831 20.1429 30.3456 20.7991L32.2811 25.2279L34.1839 20.7991C34.4791 20.1429 35.4305 20.3398 35.4305 21.0615V27.3931C35.4305 27.7539 35.1681 28.0492 34.7744 28.0492Z" fill="#540FE0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M39.8594 48.4546H3.11657C2.7557 48.4546 2.46045 48.1593 2.46045 47.7985V11.0557C2.46045 10.6948 2.7557 10.3995 3.11657 10.3995H13.1552V0.656122C13.1552 0.295255 13.4505 0 13.8114 0H39.8594C40.2203 0 40.5155 0.295255 40.5155 0.656122V14.7955H26.8354C25.8512 14.7955 25.0967 15.5829 25.0967 16.5343V31.8875C25.0967 32.8717 25.8512 33.659 26.8354 33.659H40.5155V47.7985C40.5155 48.1593 40.2203 48.4546 39.8594 48.4546ZM21.5536 29.6567H8.43116C7.54539 29.6567 7.54539 28.3445 8.43116 28.3445H21.5536C22.4066 28.3445 22.4066 29.6567 21.5536 29.6567ZM21.5536 24.867H8.43116C7.54539 24.867 7.54539 23.5548 8.43116 23.5548H21.5536C22.4066 23.5548 22.4066 24.867 21.5536 24.867ZM21.5536 20.1101H8.43116C7.54539 20.1101 7.54539 18.7979 8.43116 18.7979H21.5536C22.4066 18.7979 22.4066 20.1101 21.5536 20.1101Z" fill="#A987EF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8115 11.7118H3.11673C2.52622 11.7118 2.26377 10.9901 2.69025 10.5964L13.3522 0.196848C13.7787 -0.229631 14.4676 0.0984302 14.4676 0.656134V11.0557C14.4676 11.4493 14.1724 11.7118 13.8115 11.7118ZM4.75704 10.3995H13.1554V2.19802L4.75704 10.3995ZM41.5983 14.7956V7.54541H47.7658C48.1267 7.54541 48.4219 7.84067 48.4219 8.20153V14.7956H41.5983ZM47.7658 56H11.0558C10.6949 56 10.3997 55.7048 10.3997 55.3439V49.5372H39.8596C40.8109 49.5372 41.5983 48.7499 41.5983 47.7985V33.6591H48.4219V55.3439C48.4219 55.7048 48.1267 56 47.7658 56Z" fill="#540FE0"/>
    </g>
    <defs>
      <clipPath id="clip0_183_315">
        <rect width="56" height="56" fill="white"/>
      </clipPath>
    </defs>
  </svg></div>
  )
}
