"use client";
import React, { useState } from 'react';
import SectionTitle from '../ClassName/SectionTitle';
import Link from 'next/link';
import { Fade } from 'react-awesome-reveal';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RedirectWhite from '../icons/RedirectWhite';
import { ProjectData } from '../Types/types';
import Image from 'next/image';


interface Props {
  projects: ProjectData[];
}

export default function SomeProjects({ projects }: Props) {
  const [sliderIndex, setSliderIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    afterChange: (index: number) => {
      setSliderIndex(index);
    },
  };
  

  return (
   
      <div className=' bg-white mt-5'>
        <div className='lg:max-w-[1170px] max-w-[80%] mx-auto lg:pt-[70px] pb-[30px] lg:mb-[80px] mb-8 pt-[40px]'>
        {/* <Fade direction="left" duration={2000}> */}
          <div className='mx-auto'>
            <div className=' flex lg:flex-row flex-col justify-between items-center'>
              <div>
                <SectionTitle text=' Some of Our Projects' />
              </div>

              <div className=''>
                <div className='flex flex-col gap-5 mt-3 lg:mt-0  w-full lg:flex-row'>
                  <Link href='https://demo.hastyhead.com/' target='_blank'>
                    <button className='lg:text-[18px] text-[12px] text-white bg-[#540FE0] hover:shadow-md hover:shadow-purple-700 flex justify-start gap-2 items-center rounded-full lg:px-6 px-3 lg:py-5 py-2 font-semibold tracking-[0.36px]'>
                      Recent project
                      <span className=''><RedirectWhite /></span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className='mx-auto'>
            <Slider {...settings}>
              
            {projects.slice(0, 12).map((project: ProjectData) => (
                <div key={project.id} className=' mt-9 lg:mb-10  mb-6 '>
                  <Link href={project?.acf?.project_url} target='_blank'>
                    <div className='lg:h-[475px] h-[300px] lg:w-[360px] w-[300px]  rounded-[12px] bg-white shadow-md'>
                      <figure>
                        <Image src={project.acf.image} alt='projectimg' width={360} height={365} className='lg:h-[365px] rounded-t-[12px] mb-2 h-[215px] w-full object-cover mx-auto transition-all duration-500 ease-in-out lg:hover:mt-[-7px] hover:mt-[-5px] ' />
                      </figure>

                      <p className='lg:text-[16px] lg:h-[35px] h-[30px] overflow-hidden mb-2 text-[12px] pt-3 ms-3 my-1'>{project.acf.sub_title}</p>
                      <h2 className='lg:text-[24px] text-[18px] text-[#1B1724] font-semibold ms-3 h-[34px] overflow-hidden hover:text-[#540FE0]'>
                        {project.acf.title}
                      </h2>
                    </div>
                  </Link>
                </div>
              ))}
              
            </Slider>
          </div>
          {/* </Fade> */}
        </div>
      </div>
  
  );
}
